<template>
  <div>
    <div v-if="!isLoading" class="box box-default">
      <div class="box-header">
        <div class="flex align-items-center justify-between">
          <h3 class="box-title mb-0">Reverse Merchant CC</h3>
          <div class="flex gap-8 align-items-center justify-between">
            <label class="mb-0 no-wrap">
              <input v-model="isVerified" name="isVerified" type="radio" value="" @change="filterByFinalVerification()" />
              All</label>
            <label class="mb-0 no-wrap">
              <input v-model="isVerified" name="isVerified" type="radio" value="1"
                @change="filterByFinalVerification()" />
              Verified</label>
            <label class="mb-0 no-wrap">
              <input v-model="isVerified" name="isVerified" type="radio" value="0"
                @change="filterByFinalVerification()" />
              Unverified
            </label>
          </div>
        </div>
      </div>
      <div class="box-body no-padding">
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-condensed">
            <thead>
              <tr>
                <th>ID</th>
                <th>Merchant CC</th>
                <th>ShopTitle</th>
                <th>OwnerNickName</th>
                <th class="pointer" @click="sort('Verified')">
                  Verified
                  <span v-if="sortKey === 'Verified'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th>Disabled</th>
                <th>Final Verification</th>
                <th>Registered On</th>
                <th>Last Login On</th>
                <th class="pointer" @click="sort('LastUpdatedOn')">
                  Last Updated On
                  <span v-if="sortKey === 'LastUpdatedOn'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="records.length > 0">
                <tr v-for="(record, index) in records" :key="index">
                  <td>{{ record.ID }}</td>
                  <td>{{ record.Name }}</td>
                  <td>
                    <router-link :to="{ name: 'user-detail', params: { 'id': record.ID }, hash: '#admin-config' }">
                      {{ record.ShopTitle }}
                    </router-link>
                  </td>
                  <td>
                    <router-link :to="{ name: 'user-detail', params: { 'id': record.ID }, hash: '#admin-config' }">
                      {{ record.OwnerNickName }}
                    </router-link>
                  </td>
                  <td>
                    <span class="pointer" v-html="formatStatus(record.Verified)"></span>
                  </td>
                  <td>
                    <span class="pointer" v-html="formatStatus(record.Disabled)"></span>
                  </td>
                  <td>
                    <span class="pointer" v-html="formatStatus(record.FinalVerification)"></span>
                  </td>
                  <td>{{ record.RegisteredOn }}</td>
                  <td>{{ record.LastLoginOn }}</td>
                  <td>{{ record.LastUpdatedOn }}</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="10">No record found!</td>
                </tr>
              </template>
            </tbody>
          </table>
          <div v-if="meta.total" class="footer">
            <pagination :meta="meta" @pageChange="updatePage"></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { START_LOADING, STOP_LOADING } from "@/store/keys";
import { formatStatus, saveNotification } from "@/lib/utils";
import Pagination from "@/components/Pagination.vue";

export default {
  name: "ConfigMerchantsCC",
  components: { Pagination },
  data() {
    return {
      id: null,
      records: [],
      merchantCCTypes: [],
      sortKey: "Verified",
      order: "asc",
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: 100,
      },
      isVerified: "",
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "jwtToken",
      "currentUser",
    ]),
  },
  methods: {
    formatStatus(status) {
      return formatStatus(status === 1 ? "Yes" : "No", "60");
    },
    updatePage(page) {
      this.getRecords(page);
    },
    filterByFinalVerification() {
      this.getRecords(1);
    },
    getRecords(page = 1, isLoader = true) {
      if (isLoader) {
        this.$store.commit(START_LOADING);
      }
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListConfigMerchantsCC`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          id: this.id,
          isVerified: this.isVerified,
          orderBy: this.sortKey,
          order: this.order,
          page,
          limit: this.meta.limit,
        },
      }).then(response => {
        this.$store.commit(STOP_LOADING);
        this.records = response.data.data.records;
        this.meta = response.data.data.meta;
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    sort(key) {
      if (key !== this.sortKey) {
        this.order = "desc";
      } else {
        this.order = this.order === "asc" ? "desc" : "asc";
      }
      this.sortKey = key;
      this.getRecords();
    },
  },
  mounted() {
    if (Object.keys(this.$route.params).length > 0 && this.$route.params?.id) {
      this.id = this.$route.params.id;
      this.getRecords();
    } else {
      this.$router.push({ name: "dashboard" });
    }
  },
};
</script>

<style scoped></style>
